:root {
  --control-large-paddingBlock: 0.625rem;
  --control-medium-paddingBlock: 0.375rem;
  --control-xlarge-paddingBlock: 0.875rem;
  --control-xsmall-paddingBlock: 0.125rem;
  --overlay-height-large: 27rem;
  --overlay-height-medium: 20rem;
  --overlay-height-small: 16rem;
  --overlay-height-xlarge: 37.5rem;
  --overlay-offset: 0.25rem;
  --overlay-width-large: 40rem;
  --overlay-width-medium: 30rem;
  --overlay-width-small: 20rem;
  --overlay-width-xlarge: 60rem;
  --overlay-width-xsmall: 12rem;
  --spinner-strokeWidth-default: 0.125rem;
  --control-large-gap: 0.5rem;
  --control-large-lineBoxHeight: 1.25rem;
  --control-large-paddingInline-normal: 0.75rem;
  --control-large-paddingInline-spacious: 1rem;
  --control-large-size: 2.5rem;
  --control-medium-gap: 0.5rem;
  --control-medium-lineBoxHeight: 1.25rem;
  --control-medium-paddingInline-condensed: 0.5rem;
  --control-medium-paddingInline-normal: 0.75rem;
  --control-medium-paddingInline-spacious: 1rem;
  --control-medium-size: 2rem;
  --control-minTarget-coarse: 2.75rem;
  --control-minTarget-fine: 1rem;
  --control-small-gap: 0.25rem;
  --control-small-lineBoxHeight: 1.25rem;
  --control-small-paddingBlock: 0.25rem;
  --control-small-paddingInline-condensed: 0.5rem;
  --control-small-paddingInline-normal: 0.75rem;
  --control-small-size: 1.75rem;
  --control-xlarge-gap: 0.5rem;
  --control-xlarge-lineBoxHeight: 1.25rem;
  --control-xlarge-paddingInline-normal: 0.75rem;
  --control-xlarge-paddingInline-spacious: 1rem;
  --control-xlarge-size: 3rem;
  --control-xsmall-gap: 0.25rem;
  --control-xsmall-lineBoxHeight: 1.25rem;
  --control-xsmall-paddingInline-condensed: 0.25rem;
  --control-xsmall-paddingInline-normal: 0.5rem;
  --control-xsmall-paddingInline-spacious: 0.75rem;
  --control-xsmall-size: 1.5rem;
  --controlStack-large-gap-auto: 0.5rem;
  --controlStack-large-gap-condensed: 0.5rem;
  --controlStack-large-gap-spacious: 0.75rem;
  --controlStack-medium-gap-condensed: 0.5rem;
  --controlStack-medium-gap-spacious: 0.75rem;
  --controlStack-small-gap-condensed: 0.5rem;
  --controlStack-small-gap-spacious: 1rem;
  --overlay-borderRadius: 0.375rem;
  --overlay-padding-condensed: 0.5rem;
  --overlay-padding-normal: 1rem;
  --overlay-paddingBlock-condensed: 0.25rem;
  --overlay-paddingBlock-normal: 0.75rem;
  --spinner-size-large: 4rem;
  --spinner-size-medium: 2rem;
  --spinner-size-small: 1rem;
  --stack-gap-condensed: 0.5rem;
  --stack-gap-normal: 1rem;
  --stack-gap-spacious: 1.5rem;
  --stack-padding-condensed: 0.5rem;
  --stack-padding-normal: 1rem;
  --stack-padding-spacious: 1.5rem;
}
